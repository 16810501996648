.sectionHeight {
    height: 100vh;
  }
  
  .first-section{
      background-color: #000;
  }
  
  .video-section {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .video-section video {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -100;
    opacity: 0.8;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  
  .video-section .video-text {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 99999999;
    color: #fff;
    text-align: center;
    padding: 0 15px;
    height: 100vh;  
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .text-size {
    font-size: 2.5rem;
    position: absolute;
    font-weight: 600;
  }
  
  
  .zoomss {
    position: sticky;
    top: 0;
    will-change: transform;
    width: auto;
    transform: scale(1);
  }
  
  .wrapss {
    position: relative;
    z-index: 10000;
    height: 200vh;
  }

  @media screen and (max-width: 768px) {
    .text-size{
        font-size: 2rem;
    }
  }