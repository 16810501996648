.bg-video-wrap-contact {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.contact_main {
    position: relative;
    z-index: 99;
}

.contact_main video.fullscreen-video {
    /* width: 100%;
    height: 100vh; */
    object-fit: cover;
    width: 100%;


}

.acc_first .accordion_div ul .react-tabs__tab-list {
    display: flex;
    justify-content: center;
}

.react-tabs__tab-panel--selected {
    display: flex;
    width: 100%;
    justify-content: center;
}

.bg_social svg {
    color: black;
    font-size: 1.2rem;
    margin: 0.5vh;
}

.contact_main .accordion_div li {
    background: #1d1d1b;
    ;
}

.filtered {
    filter: brightness(0.5);
}


.overlay-div {
    position: absolute;
    top: 50%;

    transform: translate(0%, -50%);
    z-index: 99999;
    /* background-color: red; */
    padding: 20px;
    color: white;
    text-align: center;
    font-size: 20px;
    opacity: 0;
    transition: opacity 0.5s;
    height: 60vh;
    pointer-events: none;
}

.overlay-div.visible {
    opacity: 1;
    pointer-events: auto;
}

.acc_first .accordion_div {
    display: block;
    right: auto;
}

.acc_first .accordion_div ul {
    left: 0%;
    justify-content: start;
    overflow: inherit;

}

.acc_first .accordion_div li.active:nth-child(1) {
    min-width: 65vw;
}

.acc_first .accordion_div li.active .section-content {
    width: 65vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.acc_first .accordion_div ul .react-tabs__tab-list {
    min-height: auto;
}



.acc_first .career_button_inner {
    opacity: 1;
}

.acc_first .send_button {
    opacity: 1;
}

.acc_first .accordion_div li .section-title h2 {
    margin-top: 36vh;
}

.acc_first .accordion_div li.active:nth-child(2) {
    min-width: 36vw;
}

.contact-social {
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;
    color: black;


}

.bg_social {
    background-color: white;
    margin-right: 1vh;
    border-radius: 50%;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .video-web {
    display: block;
}

.gif-mobile {
    display: none;
} */

@media only screen and (max-width: 797px) {


    .acc_first .accordion_div li.active {
        width: auto;

    }

    .acc_first .accordion_div li .section-content {
        padding: 2vh;
    }

    .acc_first .bg_social {
        width: 9vw;
    }

    .acc_first ul {
        padding-left: 0rem;
    }

    .acc_first .accordion_div li .section-title h2 {
        width: 50px;
    }

    .acc_first .react-tabs {
        width: 45vw;
    }

    .acc_first .accordion_div ul .react-tabs__tab-list {
        display: block;
    }
/* 
    .video-web {
        display: none;
    } */

    /* .gif-mobile {
        display: block;
        width: 100%;
    } */

    .overlay-div {
        opacity: 1;
        top: 60%;
    }

}

@media only screen and (max-width: 992px) {
    .contact_main video.fullscreen-video {
        width: 100%;
        height: 100vh;
        object-fit: cover;


    }

    .acc_first .accordion_div li.active:nth-child(2) {
        min-width: 64vw;
    }

    .acc_first .accordion_div li .section-content {
        padding: 0vh;
        margin: 0vh;
    }

    .acc_first .accordion_div li {
        margin: 0.6vh;
    }
}