.our_tech_main {
    position: relative;
    z-index: 99;

}

.bg-video-wrap-our {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.bg-video-wrap-our_mobile {
    overflow: hidden;
    width: 100%;
}

.our_tech_main video {
    width: 100%;
    height: auto;
}

.main_icon {
    position: relative;
    margin: 0 10px;
    width: 60%;
    padding: 1vh 0vh;
    filter: brightness(1) !important;
}

.overlay-our {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(45deg,
            rgba(0, 0, 0, 0.3) 50%,
            rgba(0, 0, 0, 0.7) 50%);
    background-size: 3px 3px;
    z-index: 2;
}

.main_text {
    height: 60vh;
    border-radius: 12px;
    background-color: rgb(0 0 0 / 61%);
    position: absolute;
    text-align: start;
    font-size: 16px;
    padding: 3vh 4vh;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    opacity: 0;
    transform: translateY(0px);
}

.main_text.show {
    opacity: 1;
    transform: translateY(0);
    transform: translateX(-90%);
}

.card_inner {
    background-color: black;
    height: 60vh;
    width: 30%;
    border-radius: 12px;
}

.rotate-text {
    transform: rotate(270deg);
    transform-origin: bottom;
    white-space: nowrap;
    margin-bottom: 8vh;
    margin-left: 5vh;
    font-size: 30px;
    font-weight: 900;
}

.our_tech_main .accordion_div {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
}

.accordion {
    display: flex;
    --bs-accordion-bg: #121212;
}

.accordion-item {
    display: flex;
    flex-direction: row-reverse;
    color: white;
}

.div_body {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
}

.accordion_div ul {
    position: relative;
    left: 18%;
    display: flex;
    justify-content: end;
    min-height: 60vh;
    max-height: 60vh;
    border-radius: 12px;
    overflow: hidden;

    list-style-type: none;
}

.back_btn {
    position: absolute;
    background: black;
    height: 7vh;
    width: 7vh;
    border-radius: 50%;
    margin: 6%;

}

.accordion_div {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    transition: right 0.2s ease;
    /* overflow: hidden;  */
}

.accordion_div li {
    margin: 0.7vh;
    display: flex;
    align-items: stretch;
    background: black;
    cursor: pointer;
    transition: all 0.5s ease;
    border-radius: 12px;
}

.accordion_div li:hover {
    background: black;
}

.accordion_div li.active {
    width: 25%;
    background: rgb(19 19 19);
    cursor: default;
}

.accordion_div li.active:nth-child(1) {
    min-width: 31.5%;
}

.accordion_div li.active:nth-child(2) {
    min-width: 31.5%;
}

.accordion_div li.active:nth-child(3) {
    min-width: 32%;
}

.accordion_div li.active:nth-child(4) {
    min-width: 33%;
}

.accordion_div li.active:nth-child(5) {
    min-width: 33.5%;
}

.accordion_div li.active:nth-child(6) {
    min-width: 34.5%;
}

.accordion_div li.active:nth-child(7) {
    min-width: 35%;
}

.accordion_div li .section-title {
    background: black;
    border-radius: 12px;
}

.accordion_div li.active h2 {
    color: white;
}

.accordion_div li.active .section-content {
    text-align: start;
    width: 50%;

    opacity: 1;
    transform: scaleX(1);
    color: white;
}

.h_100 {
    height: 100vh;
}

.element-test {
    position: relative;
    width: 70vh;
    overflow-x: scroll;

    text-align: initial;
}

.accordion_div li .section-title {
    z-index: 99;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    text-align: center;
    color: white;
}

.accordion_div li .section-title h2 {
    margin: 0;
    transform: rotate(-90deg);
    white-space: nowrap;
    width: 84px;

    font-weight: 900;
    margin-top: 40vh;
}

.accordion_div li .section-content {
    flex: 1;
    padding: 4vh;
    margin: 0;
    font-size: 13px;
    opacity: 0;
}

.our_tech_main {
    display: block;
}

.our-mobile {
    display: none;
}


@media only screen and (max-width: 767px) {
    .accordion_div li .section-title h2 {
        width: 58px;
    }

    .section-content p {
        font-size: 0.7rem;
    }

    .accordion_div li {
        margin: 1.5vh;
    }

    .our_tech_main {
        height: 100vh;
    }


}

@media only screen and (max-width: 1320px) {

    .our_tech_main {
        display: none !important;
    }

    .our-mobile {
        display: block;
    }

}



@media only screen and (max-width: 1512px) and (max-height: 877px) {
    .accordion_div li {
        margin: 1vh;
    }

    .accordion_div li .section-title h2 {
        width: 70px;
        font-size: 1.5rem;
    }

    .accordion_div ul {
        left: 16%;
    }

    .accordion_div li .section-content {
        padding: 3vh;
        font-size: 12px;
    }

}

@media only screen and (min-width: 1824px) {
    .accordion_div li {
        margin: 1vh;
    }

    .accordion_div li .section-title h2 {
        width: 86px;
    }

    .accordion_div ul {
        left: 15%;
    }

    .accordion_div li .section-content {


        font-size: 17px;

    }


}

@media only screen and (min-width: 2100px) {
    .accordion_div li .section-title h2 {
        width: 115px;
    }

    .accordion_div li .section-content {

        padding: 4vh;

        font-size: 23px;

    }

    .accordion_div ul {
        left: 16%;
    }

    .accordion_div li {
        margin: 1vh;
    }

}