.App {
  text-align: center;
}

body {
  font-family: 'Montserrat', sans-serif;
  background: #1d1d1b;
  cursor: pointer;
  overflow-x: hidden !important;

}

.bg-red {
  background: red;
  border-radius: 20px;
}

.h_60 {
  height: 70vh;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin-top: 40vh;


}

.card {
  display: inline-block !important;
  width: 30vw;
}

.header_padding:hover {
  border-bottom: 1px solid white;
}

/* Kaydırma çubuğu */
::-webkit-scrollbar {
  width: 7px;
  /* Kaydırma çubuğunun genişliği */
}

/* Kaydırma çubuğu parçası (kayan kısım) */
::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
  /* Kaydırma çubuğu rengi */
}

/* Kaydırma çubuğu ok işaretleri */
::-webkit-scrollbar-button {
  background-color: #d3d3d3;
  /* Ok işaretleri rengi */
}

/* Kaydırma çubuğunun arka planı */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Arka plan rengi */
}

.text-white {
  color: white;
}

.h_100 {
  height: 100vh;
}

.header {
  position: fixed;
  z-index: 99;
  font-size: 13px;
  text-align: center;
  padding: 3vh;

}

.header_main {
  position: relative;
  z-index: 999999999;
  background-color: #1d1d1b;
  cursor: pointer;
}

.header_small {
  position: fixed;
  z-index: 99;
  text-align: center;
  padding: 2vh;
  display: none;
}

.header_small img {
  height: 5vh;

}

.landscape-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  font-size: 24px;
  color: #fff;
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .landscape-overlay {
    display: flex;
  }
}

@media screen and (max-width: 900px) and (orientation: portrait) {
  .landscape-overlay {
    display: none;
  }
}

@media screen and (min-width: 901px) {
  .landscape-overlay {
    display: none;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* 
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'IBM Plex Sans', sans-serif;
}

html {
  height: 100%;
}

body {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.background-container {
  position: fixed;
  display: flex;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
}

.video-container {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}


#bgVideo {
  flex: 1;
  background-color: #000;
  opacity: 1;
  visibility: visible;
}

.scroll-container {
  overflow: hidden;
}

.step {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: 100vh;
  width: 100%;
}

.step>div {
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 40px;
  text-shadow: 2px 2px #000;
  height: 100vh;
  width: 100%;
  color: #fff;
  opacity: 1;
} */






.header_logo {
  height: 4vh;
}

.header span {
  font-size: 12px;
}



.header_img {
  width: 10vh;
}

.header_padding {
  padding: 0.5vh;
  border-radius: 10px;
  font-weight: 900;
}

.header-flex {
  gap: 140px;

}


@media only screen and (max-width: 767px) {
  .header {
    display: none;
  }

  .header_logo {
    display: block !important;
  }
}

@media only screen and (max-width: 992px) {
  .header {
    display: none;
  }

  .header_small {
    right: 0;

    display: flex;
    justify-content: end;
  }

  .header_small img {
    height: 3vh;
  }
}


.stretch_px {



  margin-top: 5vh;
}

.hero-wrapper_px {
  position: fixed;



  z-index: -9999;
}



@media only screen and (max-width: 1512px) and (max-height: 877px) {
  .header-flex {
    gap: 100px;
  }
}