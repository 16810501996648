.text_slider {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.text_slider_main {

    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.watch-img {
    max-width: 30%;

}

.after_bg {
    width: 100%;
    position: absolute;
}

.slider-anim-text {
    font-size: 2rem;
}

.slider-relative {
    height: 100vh;
    position: relative;
    z-index: 99999;
}

.herewego-mobile {
    display: none;
}

/* textSlider.css (veya uygun stil dosyası) */
.exclude-scroll-snap {
    scroll-snap-type: none !important;
}


.herewego-web {
    display: block;
}

/* Modal stil */
.herewego-mobile .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

/* Modal içeriği */
.herewego-mobile .modal-content {
    background-color: #fff;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    position: relative;
}

/* Video */
.herewego-mobile video {
    max-width: 100%;
    height: auto;
}

/* Kapatma düğmesi */
.herewego-mobile .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    /* Düğmenin rengini istediğiniz gibi değiştirebilirsiniz */
    color: #fff;
    border: none;
    padding: 5px 5px;
    border-radius: 5px;
    z-index: 9999999999;
}

.herewego-mobile .close-button svg {
    font-size: 22px;
}



.hidden {
    display: none !important;
}

@keyframes opacity-changer {
    from {
        opacity: 0.5;
    }

    to {
        opacity: 1;
    }
}

.opacity-infinite {
    color: white;
    /* animation: opacity-changer 1s ease-out; */
}


.h_100 {
    height: 100vh;
}

/* .video_after {
    position: absolute;
    margin-top: 70vh;
} */

.a {
    opacity: 0.5;
}



.bg-video-wrap {
    position: relative;
    overflow: hidden;
    width: 100%;
    z-index: 9999;
    height: 100vh;
    /* background: url(../images/video_bg.png) no-repeat center center/cover; */
}

.bg-video-wrap video {
    min-width: 100%;
    min-height: 100vh;
    z-index: 1;
}

.overlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    /* background-image: linear-gradient(45deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 50%); */
    background-size: 3px 3px;
    z-index: 2;
}

.bg-video-wrap .text_slider {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 3;

    /* max-width: 400px;
    width: 100%;
    height: 50px; */
}


.jarallax-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.jarallax {
    position: relative;
    z-index: 0;
    filter: brightness(0.6);

}

.jarallax {
    height: 100vh;
    width: 100%;
}

.jarallax>.jarallax-img {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.text_slider .swiper {
    width: 100%;
    height: 100%;
}

.text_slider .swiper-slide {
    text-align: center;
    background: transparent;
    font-size: 2.7em;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.text_slider .swiper-vertical>.swiper-pagination-bullets,
.swiper-pagination-vertical.swiper-pagination-bullets {
    display: none !important;
}

.here-we-go-main-web {
    display: block;
}


@media only screen and (max-width: 1025px) {
    .slider-anim-text {
        font-size: 40px;

    }

    .herewego-mobile {
        display: block;
    }

    .herewego-web {
        display: none !important;
    }

    .text_slider .swiper-slide {

        font-size: 2.3em;

    }

    .h_100_mobile {
        height: 65vh !important;
        width: 91% !important;
    }
}

@media only screen and (max-width: 768px) {
    .slider-anim-text {
        font-size: 30px;

    }


}