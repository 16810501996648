.sc-gsap {
    width: 100%;
    height: 100%;
}

.g-wrap {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100vw;
    height: 100vh;
    background: #1d1d1b;
}

.g-wrap .text {
    position: relative;
    width: 800px;
    font-size: 18px;
    margin: auto;
    padding: 20px;
    color: #fff;
    background: #1d1d1b;
    overflow: hidden;
}

.g-wrap .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 400%;
    background: linear-gradient(-3deg, #1d1d1b, #1d1d1b 25%, rgb(161, 161, 161), gray, #1d1d1b 75%, #1d1d1b);
    z-index: 1;
    mix-blend-mode: darken;
}

.g-scroll {
    position: relative;
    width: 100vw;
    height: 400vw;
}

@media only screen and (max-width: 767px) {
    .g-wrap .text {
        font-size: 12px;
    }
}