*,
*::after,
*::before {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}


.container-mobile {
    max-width: 1200px;
    width: 100%;
    margin: auto;
}


.navbar-mobile {

    position: fixed;
    width: 100%;
    background: #1d1d1b;
    color: white;
    opacity: 1;
    height: 50px;
    z-index: 999999;
}

.navbar-container {

    height: 64px;

}

.menu-items-mobile {
    order: 2;
    display: flex;
}

.menu-items-mobile li {
    list-style: none;
    margin-left: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
}

.menu-items-mobile a {
    text-decoration: none;
    color: #444;
    font-weight: 900;
    transition: color 0.3s ease-in-out;
}

.menu-items-mobile a:hover {
    color: #117964;
    transition: color 0.3s ease-in-out;
}

/* CSS dosyanızda */
.menu-items-mobile {
    transform: translateX(0);
    /* Başlangıçta menüyü ekranda tutmak için 0 */
    transition: transform 0.3s ease;
}

.menu-items-mobile.closed {
    transform: translateX(100%);
    /* Menüyü sağa kaydırarak gizleme */
}

.logo-mobile {
    order: 1;
    font-size: 2.3rem;
    margin-bottom: 0.5rem;
}

.navbar-mobile {
    display: none;
}

.social-mobile {
    position: absolute;
    bottom: 15vh;
    left: 40%;
    font-size: 1.8rem;

}

.social-mobile svg {
    margin-left: 1vh;
}



@media (max-width: 992px) {
    .navbar-mobile {
        opacity: 1;
        display: block;
    }

    .navbar-container input[type="checkbox"],
    .navbar-container .hamburger-lines {
        display: block;
    }

    .navbar-container {
        display: block;
        position: relative;
        height: 64px;
    }

    .navbar-container input[type="checkbox"] {
        position: absolute;
        display: block;
        height: 32px;
        width: 30px;
        top: 20px;
        left: 20px;
        z-index: 5;
        opacity: 0;
    }

    .navbar-container .hamburger-lines {
        display: block;
        height: 17px;
        width: 28px;
        position: absolute;
        top: 17px;
        left: 20px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .navbar-container .hamburger-lines .line {
        display: block;
        height: 2px;
        width: 100%;
        border-radius: 10px;
        background: white;
    }

    .navbar-container .hamburger-lines .line1 {
        transform-origin: 0% 0%;
        transition: transform 0.4s ease-in-out;
    }

    .navbar-container .hamburger-lines .line2 {
        transition: transform 0.2s ease-in-out;
    }

    .navbar-container .hamburger-lines .line3 {
        transform-origin: 0% 100%;
        transition: transform 0.4s ease-in-out;
    }

    .navbar-mobile .menu-items-mobile {
        padding-top: 100px;
        background: #1d1d1b;
        height: 100vh;
        max-width: 150%;
        transform: translate(-150%);
        display: flex;
        flex-direction: column;
        margin-left: -40px;
        padding-left: 50px;
        transition: transform 0.5s ease-in-out;

    }

    .navbar-mobile .menu-items-mobile li {
        margin-bottom: 1.5rem;
        font-size: 1.3rem;
        font-weight: 900;
    }

    .logo-mobile {
        position: absolute;
        top: 5px;
        right: 15px;
        font-size: 2rem;
    }

    .navbar-container input[type="checkbox"]:checked~.menu-items-mobile {
        transform: translateX(0);
    }

    .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line1 {
        transform: rotate(35deg);
    }

    .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line2 {
        transform: scaleY(0);
    }

    .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line3 {
        transform: rotate(-35deg);
    }
}

@media (max-width: 500px) {




    .logo-mobile {
        position: absolute;
        top: 06px;
        right: 15px;
        font-size: 3rem;
    }

    .navbar-mobile .menu-items-mobile li {
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
        font-weight: 900;
    }
}