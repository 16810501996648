.after_bg {
    width: 100%;
    position: absolute;
}


@keyframes opacity-changer {
    from {
        opacity: 0.5;
    }

    to {
        opacity: 1;
    }
}

.opacity-infinite {
    opacity: 1;
    /* animation: opacity-changer 1s ease-out; */
}


.h_100 {
    height: 100vh;
}

.video_after {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    z-index: 99999;
    position: relative;
    background: #1d1d1b;
}

.z {
    opacity: 0.5;
    color: gray;
}