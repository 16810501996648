.career_button.react-tabs__tab--selected {
    background: linear-gradient(to right, red 50%, black 50%);
}

.hidden {
    display: none;
}

.toastify-message svg {
    height: 20vh;
}

.career-main {
    z-index: 9999;
    position: relative;
    background-color: #1d1d1b;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fixed-text {
    position: sticky;
    top: 0;
    color: white;
}

/* input#name.career_button_inner.visible {
    background: black !important;
} */

input:-internal-autofill-selected {
    background-color: red !important;
}

.svg_text {
    font-size: 1.4rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 10vh;
}

.curve_main {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.curve_height {
    position: absolute;
    display: flex;
    bottom: 0;
    left: 0;
    z-index: 9999999;
}

.text_fill {
    fill: white;
}

.svg_text path {
    fill: transparent;
}

.career_button {
    margin: 1vh;
    width: 10vw;
    border: 1px solid white;
    border-radius: 8px;
    text-align: center;
    padding: 0.5vh;
    background: linear-gradient(to right, white 50%, black 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    color: white;
    font-weight: 700;
    transition: background-position 0.3s;
}

.career_button:hover {
    background-position: left bottom;
    color: black;
    font-weight: 900;
}

.career_button_inner {
    color: gray;
    padding: 1vh;
    background: transparent;
    border-right: none;
    border-left: none;
    border-top: none;
    width: 100%;
    border-bottom: 1px solid white;
}

.career_button_inner:focus-visible {
    outline: none !important;
}

.react-tabs__tab--selected {
    background: none !important;
}

.react-tabs__tab {
    border: none !important;
}

.send_button {
    width: 100%;

    color: white;
    border-radius: 8px;
    border: 1px solid white;
    padding: 0.3vh;
    margin-top: 2vh;
    background: linear-gradient(to right, white 50%, black 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    color: white;
    font-weight: 700;
    transition: background-position 0.3s;

}

.send_button:hover {
    background-position: left bottom;
    color: black;
    font-weight: 900;
}

.career_button.active {
    background: white;
    color: black;
}

.react-tabs__tab:focus:after {
    content: none !important;
}

.react-tabs__tab-list {
    border-bottom: none !important;
    display: flex;
    justify-content: center;
}

.career_button h6 {
    margin-bottom: 0;
}

.career_form {
    z-index: 999999999;
}

.career_text {
    color: white;
    font-weight: 900;
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .curve_height {
        top: 20vh;
    }

    .career_button {
        margin: 0vh;
        width: 100%;
        font-size: 0.9rem;
    }


}

@media only screen and (max-width: 992px) {

    .career_button {
        margin: 0vh;
        width: 100%;


    }


}