.gif_height {
    height: 100vh;
}

.gif_height img {
    height: 100vh;
}

/* .glb_size {
    height: 100vh;
} */

.glb_scroll {
    position: absolute;
top: 83vh;
    height: 1%;
}

.gif-fixed {
    position: fixed;
    width: 100%;
    z-index: -1;
    background-color: #1d1d1b;
}

@media only screen and (max-width: 1024px) {


    .gif-fixed {
        position: relative;
        z-index: 9999;
    }
}

@media only screen and (max-width: 768px) {

    .glb_scroll {
        bottom: -13vh;
    }
}