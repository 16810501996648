#set-height {
    display: block;
    height: 8500px;
}

#v0 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

#video-overlay {
    position: fixed;
    top: 11vw;
    right: 22%;
    padding: 1vh;
    display: grid;
    justify-content: end;



}

.icon_desktop {
    height: 9vh;
    margin: 1vh 0vh;
}

#window {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);

    transition: transform 0.5s ease;

}