.zoom {
    position: sticky;
    top: 20vh;
    will-change: transform;
    width: auto;
    transform: scale(2.2);
}

.bottom {
    height: 100vh;
}

.top {
    height: 80vh;
}

.wrap {
    height: 100vh;
    display: flex;
    justify-content: center;
}


.our_acc_mobile a {
    display: flex;
    justify-content: end;
}

.our_acc_mobile {
    /* margin-top: 20vh; */
    background: transparent;
    overflow: hidden;
    margin-bottom: 1vh;

}


.our_acc_mobile .right {
    display: flex;
    justify-content: center;

    position: relative;

    height: 120vh;
}

.our_acc_mobile .app {
    border-radius: 10px;
    width: 85vw;
    margin: 0 auto;
    height: 100%;
    position: absolute;

    top: 50%;

    animation: intro 0.34s 0.4s cubic-bezier(1, 1.4, 0.41, 1.01) forwards;
    transform: translateY(-50%) scale(0) rotateX(10deg) rotateY(10deg) rotateZ(10deg);
    margin: auto;
    overflow: hidden;

}

.app_inner {
    position: relative;
}

.app_inner input[type="radio"] {
    display: none;
}

.app_inner input[type="radio"]:checked+label .app_inner__tab {
    height: 275px;
}

.app_inner input[type="radio"]:checked+label .app_inner__tab .tab_right {
    top: 39px;
    transition: all 0.3s 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.app_inner input[type="radio"]:not(checked)+label .app_inner__tab {
    height: 80px;
    border-left: 12px solid rgba(0, 0, 0, 0.12);
    border-bottom: 8px solid #1d1d1b;
}

.app_inner input[type="radio"]:not(checked)+label .app_inner__tab .tab_right {
    top: 200px;
    transition: all 0.3s 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.app_inner input[type="radio"]:checked+label .app_inner__tab .tab_left .tab_left__image {
    animation: move_in 0.55s 0.05s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
    transition: all 0.3s 0.36s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.app_inner input[type="radio"]:not(checked)+label .app_inner__tab .tab_left .tab_left__image {
    animation: move_out 0.75s 0s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
    transition: all 0.3s 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.app_inner input[type="radio"]:checked+label .app_inner__tab .tab_left .big {
    left: 260px;
}

.app_inner input[type="radio"]:not(checked)+label .app_inner__tab .tab_left .big {
    left: 400px;
}

.app_inner input[type="radio"]:checked+label .app_inner__tab h2 i {
    opacity: 0;
}

.app_inner input[type="radio"]:not(checked)+label .app_inner__tab h2 i {
    opacity: 0.3;
}

.app_inner label {
    display: block;
    width: 100%;
}

.app_inner label:nth-of-type(1) .app_inner__tab {
    background: #2c2b2b;
    height: 200px;
}

.app_inner label:nth-of-type(1) .app_inner__tab:hover {
    transition: all 0.2s;
    background: #131313;
}

.app_inner label:nth-of-type(1) .app_inner__tab .tab_left__image {
    background: transparent;
    border: 1px solid white;
}

.app_inner label:nth-of-type(2) .app_inner__tab {
    background: #2c2b2b;
    height: 200px;
}

.app_inner label:nth-of-type(2) .app_inner__tab:hover {
    transition: all 0.2s;
    background: #131313;
}

.app_inner label:nth-of-type(2) .app_inner__tab .tab_left__image {
    background: transparent;
    border: 1px solid white;
}

.app_inner label:nth-of-type(3) .app_inner__tab {
    background: #2c2b2b;
    height: 200px;
}

.app_inner label:nth-of-type(3) .app_inner__tab:hover {
    transition: all 0.2s;
    background: #131313;
}

.app_inner label:nth-of-type(3) .app_inner__tab .tab_left__image {
    background: transparent;
    border: 1px solid white;
}

.app_inner label:nth-of-type(4) .app_inner__tab {
    background: #2c2b2b;
    height: 200px;
}

.app_inner label:nth-of-type(4) .app_inner__tab:hover {
    transition: all 0.2s;
    background: #131313;
}

.app_inner label:nth-of-type(4) .app_inner__tab .tab_left__image {
    background: transparent;
    border: 1px solid white;
}

.app_inner label:nth-of-type(5) .app_inner__tab {
    background: #2c2b2b;
    height: 200px;
}

.app_inner label:nth-of-type(5) .app_inner__tab:hover {
    transition: all 0.2s;
    background: #131313;
}

.app_inner label:nth-of-type(5) .app_inner__tab .tab_left__image {
    background: transparent;
    border: 1px solid white;
}

.app_inner label:nth-of-type(6) .app_inner__tab {
    background: #2c2b2b;
    height: 200px;
}

.app_inner label:nth-of-type(6) .app_inner__tab:hover {
    transition: all 0.2s;
    background: #131313;
}

.app_inner label:nth-of-type(6) .app_inner__tab .tab_left__image {
    background: transparent;
    border: 1px solid white;
}

.app_inner label:nth-of-type(7) .app_inner__tab {
    background: #2c2b2b;
    height: 200px;
}

.app_inner label:nth-of-type(7) .app_inner__tab:hover {
    transition: all 0.2s;
    background: #131313;
}

.app_inner label:nth-of-type(7) .app_inner__tab .tab_left__image {
    background: transparent;
    border: 1px solid white;
}

.app_inner__tab {
    width: 100%;
    height: 80px;
    background: red;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: all 0.65s cubic-bezier(1, 0, 0.41, 1.01);
}

.app_inner__tab h2 {
    position: absolute;
    right: 20px;
    top: 16px;
    color: white;
    font-size: 18px;
    font-weight: 900 !important;
}

.app_inner__tab h2 i {
    position: absolute;
    right: 271px;
    opacity: 0.3;
    transition: all 0.3s;
}

.app_inner__tab .tab_right {
    width: 60%;
    float: right;
    position: relative;
    top: 200px;
    text-align: right;
    padding: 20px;
}

.app_inner__tab .tab_right h3,
.app_inner__tab .tab_right h4 {
    margin: 0;
}

.app_inner__tab .tab_right h3 {
    font-size: 10px;
    color: white;
}

.app_inner__tab .tab_right h4 {
    font-size: 12px;
    margin: 4px 0px;
    color: rgba(0, 0, 0, 0.33);
}



.app-icon img {
    margin-top: 10px;

    width: 80%;


    cursor: pointer;

    font-size: 11px;

    transition: all 0.3s;
}

.app_inner__tab .tab_right button:hover {
    background: white;
    color: black;
}

.app_inner__tab .tab_left {
    width: 30%;
    float: left;
    position: relative;
}

.app_inner__tab .tab_left .big {
    position: absolute;
    top: -17px;
    transition: all 0.3s 0.3s;
    left: 390px;
    font-size: 180px;
    opacity: 0.08;
}

.app_inner__tab .tab_left__image {
    position: relative;
    top: -100px;
    text-align: center;
    background: white;
    left: 47px;
    border-radius: 20px;
    width: 80px;
    height: 80px;
}

.app_inner__tab .tab_left__image img {
    color: white;
    font-size: 40px;
    top: 18px;
    position: relative;
}

@keyframes move_out {
    0% {
        top: 47px;
    }

    100% {
        top: 200px;
    }
}

@keyframes move_in {
    0% {
        top: -200px;
    }

    100% {
        top: 47px;
    }
}

@keyframes bump {
    0% {
        top: 16px;
    }

    25% {
        top: 13px;
    }

    50% {
        top: 16px;
    }

    75% {
        top: 13px;
    }

    100% {
        top: 16px;
    }
}

@keyframes intro {
    0% {
        transform: translateY(-50%) scale(0) rotateX(10deg) rotateY(10deg) rotateZ(40deg);
    }

    100% {
        transform: translateY(-50%) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
}

.our_acc_mobile a.download,
a.follow {
    text-align: center;
    margin-top: 13px;
    width: 300px;
    float: left;
    display: inline-block;
    padding: 16px 30px;
    background: #ea495f;
    color: white;
    font-weight: 900;

    text-decoration: none;
    border-radius: 4px;
    margin-right: 12px;
}

.our_acc_mobile a.download i,
a.follow i {
    margin-right: 10px;
}

.our_acc_mobile a.follow {
    float: left;
    padding: 14px 30px;
    background: none;
    color: white;
    border: 2px solid white;
}

.our_acc_mobile a.follow:hover {
    color: #41efb6;
    border-color: #41efb6;
}

@media only screen and (max-width: 992px) {

    /* .wrap {
        height: 97vh;

    } */
}

@media only screen and (max-width: 767px) {

    .our_acc_mobile {
        margin-top: 0vh;
    }

    .wrap {
        height: 104vh;

    }

}