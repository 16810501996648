.model_screen {
    width: 100%;
    height: 105vh;
    position: sticky !important;
    /* overflow-y: scroll; */
    background: #1d1d1b;

}

main {
    background-color: #1d1d1b;
    border: 1px solid #1d1d1b;
}

.inner_logo {
    filter: brightness(0);

}

.item_logo {
    color: black;
}

.item_name {
    color: black;
    border: none;
}

a {
    border: none;
    color: white !important;
    text-decoration: none !important;
}

.bg_inner_header {
    background-color: #1d1d1b;
}

.osi_img_inner {
    filter: brightness(1);
}

.sc_gif {
    width: 50%;
}

.footer-main {
    background: #1d1d1b;
    position: relative;

}

.model-bg {
    background: #1d1d1b;
}

.footer-main p {
    font-size: 0.8rem;
}

.content_screen {
    width: 332px;
    height: 216px;
    background: #1d1d1b;
    border-radius: 3px;
    overflow-y: auto;
    padding: 0;
    overflow-x: hidden;
    position: relative;
    top: 1vh;
}

.wrapper_screen {
    padding: 10px;
    width: 668px;
    height: 432px;
    transform: scale(0.5);
    transform-origin: top left;
}

.model_screen {
    position: relative;
}

.scroll_div {
    position: absolute;
    top: 30%;
    right: 100px;

}

.inner_menu_logo {

    z-index: 99999;
    position: absolute;
}

.inner_menu_logo img {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    height: 70%;

}





.svg_text {
    font-size: 3.5rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    top: 30vh;
    position: relative;
}

.curve_height {

    position: absolute;

    left: 0;
}

.text_fill {
    fill: white;
}

.svg_text path {
    fill: transparent;
}

@media only screen and (max-width: 767px) {
    .model_screen {
        width: 100%;
        height: 60vh;
        overflow-y: scroll;


    }

    .content_screen {

        top: -1vh;
    }


    .card-inner-text h5 {
        font-size: 0.8rem;
    }

    .card-inner-text h2 {
        font-size: 1.3rem;
    }

}

@media only screen and (max-width: 992px) {
    .model_screen {
        width: 100%;
        height: 60vh;
        overflow-y: scroll;


    }

    .content_screen {
        width: 335px;
        height: 216px;

        top: -2vh;
    }



}

@media only screen and (max-width: 1512px) and (max-height: 877px) {
    .content_screen {
        top: 0.6vh;
    }
}