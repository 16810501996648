@font-face {
    font-family: "Effra Light";
    src: url("https://db.onlinewebfonts.com/t/cb125877edce66887e4e20644cd84bd7.eot");
    src: url("https://db.onlinewebfonts.com/t/cb125877edce66887e4e20644cd84bd7.eot?#iefix")format("embedded-opentype"),
        url("https://db.onlinewebfonts.com/t/cb125877edce66887e4e20644cd84bd7.woff2")format("woff2"),
        url("https://db.onlinewebfonts.com/t/cb125877edce66887e4e20644cd84bd7.woff")format("woff"),
        url("https://db.onlinewebfonts.com/t/cb125877edce66887e4e20644cd84bd7.ttf")format("truetype"),
        url("https://db.onlinewebfonts.com/t/cb125877edce66887e4e20644cd84bd7.svg#Effra Light")format("svg");
}

.create_main {
    background: #1d1d1b;
    z-index: 99;
    width: 100%;
}


.bg-black {
    background: black;
    border-radius: 20px;
}

.overlay-text-us {
    position: relative;
    display: inline-block;
}

/* Kaydırma çubuğu */
.create_main ::-webkit-scrollbar {
    width: 1px;
    height: 7px;
    /* Kaydırma çubuğunun genişliği */
}

/* Kaydırma çubuğu parçası (kayan kısım) */
.create_main ::-webkit-scrollbar-thumb {
    background-color: white;
    /* Kaydırma çubuğu rengi */
}

/* Kaydırma çubuğu ok işaretleri */
.create_main ::-webkit-scrollbar-button {
    background-color: gray;
    width: 1px;
    /* Ok işaretleri rengi */
}

/* Kaydırma çubuğunun arka planı */
.create_main ::-webkit-scrollbar-track {
    background-color: gray;
    /* Arka plan rengi */
}

.overlay-text-us-inner {
    position: absolute;

    left: 0%;

    bottom: 0;
    color: white;
    padding: 10px;
    border-radius: 5px;
}

.margin-div {
    margin: 1vh;
}

.create_main .end-ins .cycle {
    max-width: 110%;

}

.create_main .end-ins .stuff {
    max-width: 100%;
    width: 100vh;
    margin-bottom: 13vh;
}

.create_main .end-ins .cycle-logo {
    max-width: 38%;
}

.max-img {
    max-width: 145vh;
}

.create_main h6 {
    font-size: 0.8rem;
}

.mt-7 {
    margin-top: 7vh;
}

.rel-img {
    position: relative;
    left: 48vh;
    top: -7vh;
}

.text-tripy {
    color: #f16524;
}

.large-div-text {
    margin: 5vh;
}

.font-exp {
    position: relative;
    top: 38vh;
    left: 65vh;
    width: 40vw;
}

.web-text {
    position: absolute;
    top: 12vh;
    font-weight: 900;
}

.font-text-desc {
    position: absolute;
    margin-top: -15vh;

    text-align: end;
}

.font-exp h6 {
    font-size: 0.7rem;
}

.website {
    position: relative;
    left: 153vh;
}

.font-exp h5 {
    font-size: 0.9rem;
}

.color-p {
    max-width: 40%;
}

.color-p-desc h6 {
    font-size: 0.7rem;
    color: rgb(154, 153, 153);
}

.color-p-desc h5 {
    font-weight: 900;
}

.overlay-text-creative {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 4vh;
    width: 100%;

    color: white;
    display: none;
    flex-direction: column;

}

.main-crowne {
    max-width: 50%;
}

.main-nook {
    max-width: 75%;
}

.overlay-text-crowne {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4vh;
    width: 100%;

    color: white;
    display: none;
    flex-direction: column;

}

.circle_icon {
    font-size: 90px;
}

.create_main svg {

    width: 2vw;
    color: white;
    position: relative;

    font-weight: 900;
}

/* CSS for the animations */
.slide-in {
    animation: slide-in-animation 0.5s forwards;
}

.slide-out {
    animation: slide-out-animation 0.5s forwards;
}

@keyframes slide-in-animation {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slide-out-animation {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.create_main .close-button {
    font-size: 3rem;
    color: white;
}

.end-ins {
    height: 100%;
    background: #1d1d1b;
    color: white;
    display: flex;

    overflow-x: scroll;
    overflow-y: hidden;

    scroll-behavior: smooth;
    -moz-hyphens: manual;
    -webkit-hyphens: manual;
    -webkit-overflow-scrolling: touch;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;



    white-space: nowrap;
    width: 90%;
    height: 75vh;
    border: 4px solid dimgray;
    margin: 1vh;
    border-radius: 12px;
}

.w-30 {
    width: 30vw;
}

.w-100 {
    width: 100vw;
}

.end-ins .text {
    padding: 0 9rem;
}

.end-ins .text-ciftay {
    padding: 0 9rem;
}

.end-ins h1,
h2,
h3,
h4,
h5,
h6 {
    text-rendering: geometricPrecision;
}

.end-ins a {
    color: green;
    transition: 110ms ease;
    font-weight: 400;
}

.end-ins a:hover {
    box-shadow: 0px 4px 0px yellow;
}

.end-ins h1 {
    font-size: 6rem;
    line-height: 1;
}

.end-ins h2 {
    font-size: 5rem;
    font-weight: 700;
    margin-bottom: 3rem;
}

.end-ins h3 {
    font-size: 2rem;
}

.end-ins p {
    font-size: 2rem;
    line-height: 1.35;
    max-width: 80rem;
}

.end-ins strong {
    font-weight: 800;
}

.end-ins header {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.end-ins nav {
    margin-top: 1.8rem;
}

.end-ins ul {
    list-style: none;
    display: inline-flex;
    position: relative;
}

.create_main .swiper-slide {
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.end-ins ul li a {
    color: green;
    margin-right: 1.5rem;
    font-size: 1.8rem;
    text-decoration: none;
}

.end-ins main {
    display: flex;
}

.end-ins section {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #1d1d1b;
}

.w-40 {
    width: 40vw !important;
}

.w-10 {
    width: 10vw;
}

.w-25 {
    width: 25vw;
}

.ml-13 {
    margin-left: 13vh;
}

.end-ins section:nth-child(1) {
    background: #1d1d1b;
    margin: 0;

    display: flex;
    justify-content: center;
}

.create_main .main-img {
    max-width: 70%;
}

.color-margin {
    margin-top: 7vh;
}

.no-swipe .swiper-slide {
    pointer-events: none;
}

.mia-logo {
    max-width: 28%;
}

.mision-text {
    font-size: 0.7rem !important;
}

.fill {
    margin: 10px 0 0 0;
    width: fit-content;
    -webkit-text-fill-color: white;
    -webkit-text-stroke: 1px #1d1d1b;
    position: relative;
    font-size: 3.3rem !important;
    margin-bottom: 1rem !important;

}

.flex-initial {
    flex-direction: initial !important;
}

.w-150 {
    width: 150% !important;
}

.mia-palette {
    max-width: 68%;
}

.mia-end {
    justify-content: end;
}

.mia-folder {
    max-width: 150%;

}

.mia-kart {
    max-width: 40%;
    height: 40%;
}

.mia-typ {
    max-width: 100%;
}


.mia-stuff {
    max-width: 75%;
    margin-bottom: 22vh;
}

.mia-hoddie {
    max-width: 100%;
    margin-bottom: 20vh;
}

.mia-sol {
    max-width: 75%;
    margin-bottom: 28vh;
}

.lamots-logo {
    max-width: 56%;
}

.lamots-typ {
    max-width: 68%;
}

.lamots-journey {
    max-width: 100%;
    margin-bottom: 20vh;
}

.create_main .end-ins .ciftay-logo {
    max-width: 16%;
}

.nook-type {
    display: block !important;
    margin: 15vh 0vh 0vh 15vh !important;
}

.create_main .end-ins .enerjey2-logo {
    max-width: 23%;
}

.create_main .end-ins .m-4 {
    margin: 4.2vh !important;
}

.create_main .end-ins .stuff_ciftay {
    max-width: 88%;
    margin-bottom: 23vh;
    margin-left: -18vh;
}

.create_main .end-ins .stuff_enerjey {
    max-width: 78%;
    margin-bottom: 23vh;
}

.mobile-none {
    width: 20vw !important;
}

.enerjey-logo {
    max-width: 66%;
}

.crowne-pattern {
    max-width: 100%;
}

.create_main .end-ins .stuff_enerjey {
    max-width: 100%;
    margin-bottom: 26vh;
}

.nook-logo {
    max-width: 40%;
}

.nook-logo-2 {
    max-width: 70%;
}

.create_main .end-ins .stuff_nook {
    max-width: 80%;
    margin-bottom: 17vh;
}

.ciftay-typ {
    max-width: 120%;
}

.ciftay-foo {
    max-width: 40%;
}

.website-img {
    margin-top: -23vh;
    max-width: 155vh;
}

.lamots-inner-logo {

    margin-top: 15vh;
}

.lamots-palette {
    max-width: 66%;
    margin-top: 10vh;
}

.nook-typ {
    max-width: 65vw;
}

.nook-font {
    max-width: 79%;
}

.nook-palette {
    max-width: 95%;
    margin-top: 5vh;
}

.crowne_brand {

    margin-bottom: 20vh;
}

.ciftay-color {
    max-width: 65%;
    margin-bottom: 25vh;
}

.stuff_lamots {
    margin-top: -28vh;
}

@media only screen and (max-width: 767px) {
    .top-tripy {
        margin-top: 20vh;
    }

    .overlay-text-creative {
        padding: 1vh;


    }

    .container-grid-second .ml-4 {
        margin-left: 0;
    }

    .main-mia {
        max-width: 80%;
    }

    .container-grid-second {
        position: initial !important;
    }

    .container-grid-first {
        position: initial !important;
    }

    .ciftay-foo {
        max-width: 20%;
    }

    .mia-end {
        justify-content: start;
    }

    .stuff_lamots {
        margin-top: 22vh;

    }

    .create_main .end-ins .stuff_ciftay {
        margin-top: 10vh;
    }

    .ciftay-typ {
        width: 80vw;

    }

    .nook-palette {
        max-width: 55%;
        margin-top: 13vh;
    }

    .ciftay-color {
        max-width: 50%;
        margin-bottom: 25vh;
    }

    .create_main .end-ins .stuff_nook {
        max-width: 60%;
        margin-top: 10vh;
    }

    .nook-typ {

        margin-top: 13vh;
    }

    .lamots-inner-logo {
        max-width: 60%;
        margin-top: 12vh;
    }

    .nook-logo-2 {
        max-width: 19%;
    }

    .nook-logo {
        max-width: 24%;
    }

    .ml-13 {
        margin-left: 75vh;
    }

    .lamots-palette {
        max-width: 90%;
        margin-top: 6vh;
    }

    .create_main .main-img {
        max-width: 54%;
    }



    .create_main svg {
        width: 7vw;
    }

    .create_main {
        top: 10vh;
        position: relative;
    }

    .create_main .swiper-slide {
        height: 100%;
    }

    .mia-kart {
        max-width: 20%;
    }

    .w-150 {
        width: 90% !important;

    }

    .mia-logo {
        max-width: 27%;
    }

    .color-p {

        max-width: 10%;
    }

    .mia-hoddie {
        max-width: 110%;
        margin-top: 33vh;
    }

    .lamots-logo {
        max-width: 58%;
    }

    .lamots-typ {
        max-width: 71%;

    }

    .mia-sol {
        max-width: 100%;
        margin-top: 6vh;
    }

    .mia-stuff {
        max-width: 108%;
        margin-top: 25vh;
    }

    .margin-div {
        width: 80vw;
        height: 40vh;
    }

    .card-mobile {
        display: flex;
        justify-content: center;
    }


    .end-ins .text {
        padding: 0 3rem;
    }

    .end-ins .text-ciftay {
        padding: 0rem;
    }

    .end-ins header {
        width: 140vw;
    }

    .create_main h6 {
        font-size: 0.9rem;
    }

    .create_main h3 {
        font-size: 2rem;

    }

    .end-ins section {
        width: 250vh;
        display: block;
        top: 0vh;
    }

    /* .create_main .end-ins .cycle-logo {
        max-width: 10%;
    } */
    .mobile-center-scroll {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .color-margin {
        margin: 1vh;
    }

    .color-p-desc h5 {
        font-size: 0.8rem;

    }

    .rel-img {
        left: -15vh;
        top: 30vh;
    }

    .max-img {
        max-width: 78vh;
    }

    .font-exp {
        top: 12vh;
        width: 70vw;
        left: -10vh;
    }

    .font-container {
        display: flex;
    }

    .font-text-desc {
        text-align: start;
        margin-top: 0;


    }


    /* .create_main .end-ins .cycle {
        max-width: 37%;
    } */

    .website {
        left: -10vh;
    }



    .create_main .end-ins .stuff {
        max-width: 90% !important;
        width: 100vh;
        margin-top: 20vh;
    }

    .create_main .end-ins .tripy {
        height: 20% !important;
    }

    .mobile-none {
        display: none;
    }

    .web-text {
        top: 5vh;
    }

    .end-ins p {
        font-size: 1.5rem;
    }

    .website-img {
        max-width: 60%;
        margin-top: 20vh;
    }

    .mia-palette {
        max-width: 47%;
    }

    .mia-typ {

        max-width: 55%;
    }

    .nook-font {
        max-width: 50%;
        margin-top: 8vh;
    }

    .create_main .end-ins .enerjey2-logo {
        max-width: 19%;
    }

    .create_main .end-ins .stuff_enerjey {
        max-width: 70%;
        margin-top: 15vh;
    }

    .crowne-pattern {
        max-width: 57%;
        margin-top: 16vh;
    }

    .crowne_brand {
        max-width: 60%;
        margin-top: 15vh;
    }

}

.container-grid-first {
    position: absolute;
    bottom: 0;
}

.container-grid-second {
    position: absolute;
    top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .container-grid-first {
        position: initial;

    }

    .margin-div {
        margin: 0 0 2vh 0vh;
    }

    .container-grid-second {
        position: initial;

    }

    .mia-end {
        justify-content: start;
    }

    .container-grid-second .ml-4 {
        margin-left: 0;
    }

    .end-ins section {
        width: 260vw;
    }

    .lamots-inner-logo {
        max-width: 75%;
    }

    .w-150 {
        width: 150% !important;
    }

    .lamots-palette {
        max-width: 85%;
        margin-top: 10vh;
    }

    .mia-stuff {
        max-width: 100%;

    }

    .mia-logo {
        max-width: 40%;
    }

    .ciftay-color {
        max-width: 60%;
        margin-bottom: 25vh;
    }

    .crowne-pattern {
        margin-top: 10vh;
    }

    .create_main .end-ins .stuff_nook {
        max-width: 90%;

    }

    .nook-palette {
        max-width: 100%;
        margin-top: 10vh;
    }

    .crowne_brand {
        max-width: 80%;
        margin-bottom: 25vh;
    }

    .create_main .end-ins .enerjey2-logo {
        max-width: 25%;


    }

    .nook-font {
        max-width: 100%;
        margin-top: 5vh;
    }

    .nook-typ {
        max-width: 55vw;
        margin-top: 10vh;
    }

    .create_main svg {
        width: 5vw;
    }

    .create_main .main-img {
        max-width: 50%;
    }

    .create_main .swiper-slide {
        height: 100%;
    }



    .end-ins {
        margin-top: 9vh;
    }










}